import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './theme/app.css'

/*global google*/

// Do not check for ion components.
Vue.config.ignoredElements = [/^ion-/]

import { io } from "socket.io-client";
import VeeValidate from 'vee-validate';
import VueMeta from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faBell,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons';


import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCloudUploadAlt
} from '@fortawesome/free-solid-svg-icons';

import i18n from './i18n'
import constants from './constants';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faBell, faPaperPlane, faCloudUploadAlt);

// Vuetify UI Components **** https://vuetifyjs.com/ ****
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
Vue.use(VeeValidate);
Vue.use(VueMeta);
Vue.component('font-awesome-icon', FontAwesomeIcon);
import AnimateCSS from 'animate.css';
Vue.use(AnimateCSS);

Vue.prototype.$socket = io(constants.SERVER_URL);

google.maps.Polygon.prototype.getBounds = function() {
  var bounds = new google.maps.LatLngBounds();
  var paths = this.getPaths();
  var path;        
  for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
      }
  }
  return bounds;
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
