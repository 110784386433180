import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MasterPage from '../MasterPage.vue';
import Login from '../views/auth/Login.vue';
import Register from '../views/auth/Register.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import NotFound from '../views/404.vue'
import ContractRefused from '../views/ContractRefuse.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MasterPage,
    children: [
      { path: '/', name: 'home', component: Home },
      { path: '/reports', name: 'reports', component:  () => import('../views/projects/Reports.vue') },
      { path: '/report/:id', name: 'report', component: () => import('../views/projects/Report.vue') },
      
      { path: '/customer/:id', name: 'customer', component: () => import('../views/profiles/Customer.vue') },
      { path: '/customers', name: 'customers', component: () => import('../views/profiles/Customers.vue') },
      { path: '/dealer/:id', name: 'dealer', component: () => import('../views/profiles/Dealer.vue') },
      { path: '/dealers', name: 'dealers', component: () => import('../views/profiles/Dealers.vue') },
      { path: '/user/:id', name: 'user', component: () => import('../views/profiles/User.vue') },
      { path: '/users', name: 'users', component: () => import('../views/profiles/Users.vue') },

      { path: '/map', name: 'map', component: () => import('../views/Map.vue')},

      { path: '/settings', name: 'settings', component: () => import('../views/settings/Settings.vue') },
      { path: '/settings/projecttypes', name: 'projecttypes', component: () => import('../views/settings/ProjectTypes.vue') },
      { path: '/settings/security', name: 'security', component: () => import('../views/settings/Roles.vue') },
      { path: '/settings/plates', name: 'plates', component: () => import('../views/settings/Plates.vue') },
      { path: '/settings/groups', name: 'groups', component: () => import('../views/settings/Groups.vue') },
      { path: '/settings/videos', name: 'videos', component: () => import('../views/settings/Videos.vue') },
      { path: '/settings/notificationManuals', name: 'notificationManuals', component: () => import('../views/settings/NotificationManuals.vue') },

      { path: '/engineeringDepartments', name: 'engineeringDepartments', component: () => import('../views/engineeringDepartments/EngineeringDepartments.vue') },
      { path: '/engineeringDepartments/listmachines', name: 'listMachines', component: () => import('../views/engineeringDepartments/ListMachines.vue') },

      { path: '/mobile-apps', component: () => import('../views/MobileApp.vue') },

      { path: '/videos', component: () => import('../views/Videos.vue')},
      { path: '/videos/:id', component: () => import('../views/Video.vue')},

      { path: '/marketing', component: () => import('../views/Marketing.vue') },

      { path: '/projects', name: 'projects', component:  () => import('../views/projects/Projects.vue') },
      { path: '/project/:id', name: 'project', component: () => import('../views/projects/Project.vue') },
      { path: '/project/:id/documentRequestIdDownload/:documentRequestIdDownload', component: () => import('../views/projects/Project.vue')}, // A path and an alias should contain the same amount of params.

      { path: '/documentRequests', name: 'documentRequests', component:  () => import('../views/projects/DocumentRequests.vue') },
      { path: '/documentRequest/:id/upload', name: 'documentRequestUpload', component: () => import('../views/projects/DocumentRequestUpload.vue') },

      { path: '/notificationsConfig', name: 'notificationsConfig', component: () => import('../views/NotificationsConfig.vue') },

      { path: '/flush', name: 'flush', redirect: '/' }
    ]
  },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password/:id/:token', component: ResetPassword },
  { path: '/404', component: NotFound },
  { path: '/contract-refused', component: ContractRefused},
  { path: '*', redirect: '/404' },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password'];
  //const authRequired = !publicPages.includes(to.path);
  let authRequired = true;
  for (let i = 0; i < publicPages.length; i++) {
    if (to.path.includes(publicPages[i])) {
      authRequired = false;
    }
  }
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    let returnUrl = window.location.pathname;
    next('/login?returnurl=' + returnUrl);
  } else {
    if (loggedIn) {
      store.dispatch('initAppData');
    }
    next();
  }

  if (to?.redirectedFrom == '/flush') {
    console.log('flush');
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
});

export default router
